exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-experts-js": () => import("./../../../src/pages/experts.js" /* webpackChunkName: "component---src-pages-experts-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-prototypes-js": () => import("./../../../src/pages/prototypes.js" /* webpackChunkName: "component---src-pages-prototypes-js" */),
  "component---src-templates-expert-js": () => import("./../../../src/templates/expert.js" /* webpackChunkName: "component---src-templates-expert-js" */),
  "component---src-templates-prototype-js": () => import("./../../../src/templates/prototype.js" /* webpackChunkName: "component---src-templates-prototype-js" */)
}

